import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditController } from 'react-admin';

import DeleteButton from '../DeleteButton';
import DetailForm from '../Form/DetailForm';

import useExportData from '../../utils/useExportData';

const T = (props) => {
  return (
    <div className='flex row'>
		<div className='flex-item'>
		<DetailForm formLabel='Hegner & Moeller Übermittlung' boxClassName='contact' {...props} noEditMode>
			<pre>{JSON.stringify(props.record?.status?.externalServices?.hegnerMoeller?.transformed, null, 2)}</pre>
        </DetailForm>
      </div>
      <div className='flex-item'>
        <DetailForm formLabel='Hegner & Moeller Fehler' boxClassName='contact' {...props} noEditMode>
			<pre>{JSON.stringify(props.record?.status?.externalServices?.hegnerMoeller?.error_field, null, 2)}</pre>
        </DetailForm>
      </div>
    </div>
  );
};

export default (props) => {
  const history = useNavigate();
  const editProps = useEditController({ ...props, undoable: false });
  const { exportCallback, viewCallback } = useExportData(editProps.record);

  const goBackCallback = useCallback(() => {
    history(-1);
  }, [history]);

  return (
    <div className={'details'}>
      <div className='flex space'>
        <h2 className='symbol'>Lead</h2>
        <button onClick={goBackCallback} className='btn btn-border'>
          <i className='icon icon-chevron-left'></i>Zurück
        </button>
      </div>
      <T {...editProps} />
      <button className='export' onClick={exportCallback}>
        Export
      </button>
      <button className='export' onClick={viewCallback}>
        View
      </button>
      {editProps.record && <DeleteButton {...editProps} />}
    </div>
  );
};
