import React from "react";

import List from './List';
import DataGrid from './List/DataGrid';
import TextField from './List/TextField';
import DateField from './List/DateField';
import HegnerMoellerStatusField from "./List/HegnerMoellerStatusField";

const defaultSort = { field: 'createdAt', order: 'DESC' };


export default (props) => {
  return (
    <List {...props} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <HegnerMoellerStatusField source="status.externalServices.hegnerMoeller.success" label="Status" notSearchable />
        <DateField source="createdAt" label="Datum" showTime notSearchable />
        <TextField source="applicant.lastname" label="Kontakt" notSearchable />
        <TextField source="caseToken" label="Case Token" />
        <TextField source="website" label="Quelle"  />
      </DataGrid>
    </List>
  );
};